<template>
    <div class="aco">
        <div class="aco__head">
            <a @click="acoToggle">
                <div class="aco__tit"><slot name="head"></slot></div>
                <div class="aco__icon" :class="{ 'is-active': isActive }"></div>
            </a>
        </div>
        <transition name="js-aco" @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
            <div class="aco__body-wrapper" v-show="isActive">
                <div class="aco__body">
                    <div class="aco__inner">
                        <slot name="body"></slot> 
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isActive: false
        }
    },

    methods: {
        acoToggle() {
			this.isActive = !this.isActive
		},

		beforeEnter(el) {
			el.style.height = '0';
		},

		enter(el) {
            el.style.height = el.scrollHeight + 'px';
		},

		beforeLeave(el) {
            el.style.height = el.scrollHeight + 'px';
		},

		leave(el) {
            el.style.height = '0';
		}
    },
}
</script>